import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '..';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { getLeituraCaixa } from '~/pages/PdvOnline/services/getLeituraCaixa';
import { LeituraData } from '~/pages/PdvOnline/types';
import { Leitura } from './components/Leitura';
import { TbCoins } from 'react-icons/tb';
import { RiShoppingBasket2Line } from 'react-icons/ri';
import { IoPrintOutline, IoWalletOutline } from 'react-icons/io5';
import { Spinner } from 'react-bootstrap';
import { IconType } from 'react-icons';
import { ContainerButtonModal } from './styles';
import ImprimirLeituraCaixa from '~/utils/classes/ImpressaoRecibo/PdvOnline/ImprimirLeituraCaixa';
import { gerarCupomCaixa } from '../ModalBuscarVendas/utils/gerarCupomCaixa';
import { calcularTotaisLeitura } from '~/pages/PdvOnline/utils/calcularTotaisLeitura';

export const ModalLeituraDeCaixa: React.FC = () => {
  const {
    isModalOpen,
    initialStatePdv,
    onCloseModal,
    handleChangeOpenModalFunction,
  } = usePdvOnline();

  const [leitura, setLeitura] = useState<LeituraData>({
    abertura: null,
    vendas: null,
    reforco: null,
    sangria: null,
  });
  const [totals, setTotals] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const delay = useCallback((ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }, []);

  const modalOpen =
    isModalOpen.isOpen && isModalOpen.modal === 'leituraDeCaixa';

  useEffect(() => {
    if (modalOpen && initialStatePdv) {
      const leituraDeCaixa = async () => {
        setLoading(true);

        await delay(200);

        const { cod_loja, num_pdv, cod_operador } = initialStatePdv;
        const { success, data } = await getLeituraCaixa(
          cod_loja,
          num_pdv,
          cod_operador,
        );

        if (success) {
          const { abertura_json, vendas_json, reforco_json, sangria_json } =
            data;
          const leituras = {
            abertura: abertura_json,
            vendas: vendas_json,
            reforco: reforco_json,
            sangria: sangria_json,
          };
          setLeitura(leituras);
          const totaisLeitura = calcularTotaisLeitura(leituras);
          setTotals(totaisLeitura);
        }

        setLoading(false);
      };

      leituraDeCaixa();
    }
  }, [delay, initialStatePdv, modalOpen]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();
    handleChangeOpenModalFunction(true);
    setLeitura({
      abertura: null,
      vendas: null,
      reforco: null,
      sangria: null,
    });
    setLoading(false);
    setTotals([]);
  }, [onCloseModal, handleChangeOpenModalFunction]);

  const renderLeitura = useCallback(
    (icon: IconType, label: string, data, key: string) => (
      <Leitura
        icon={icon}
        label={label}
        finalizadoras={
          data
            ? data.map((val: any) => ({
                value: val[`val_${key.toLowerCase()}`],
                ...val,
              }))
            : null
        }
      />
    ),
    [],
  );

  useEffect(() => {
    const closeModal = (ev: KeyboardEvent) => {
      if (modalOpen && (ev.key === 'Escape' || ev.key === 'Enter'))
        handleCloseModal();
    };

    window.addEventListener('keydown', closeModal);
    return () => window.removeEventListener('keydown', closeModal);
  }, [handleCloseModal, modalOpen]);

  const handleImprimir = useCallback(async () => {
    if (loading || !modalOpen || !initialStatePdv) return;

    const { abertura, vendas, reforco, sangria } = leitura;
    if ((!abertura && !vendas && !reforco && !sangria) || totals.length === 0)
      return;

    const loja = {
      cod_loja: Number(initialStatePdv.cod_loja),
      cod_operador: Number(initialStatePdv.cod_operador),
      num_pdv: Number(initialStatePdv.num_pdv),
    };

    const data = { leitura, saldo_final: totals, loja };

    const leituraCaixa = new ImprimirLeituraCaixa(data);
    const created = await leituraCaixa.create();
    gerarCupomCaixa(created, 'open');
  }, [initialStatePdv, leitura, loading, modalOpen, totals]);

  return (
    <Modal
      isOpen={modalOpen}
      title="Leitura de caixa"
      onClose={handleCloseModal}
    >
      {loading ? (
        <div
          style={{
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner animation="border" size="sm" />
        </div>
      ) : (
        <section>
          {renderLeitura(TbCoins, 'Abertura', leitura.abertura, 'abertura')}
          {renderLeitura(
            RiShoppingBasket2Line,
            'Vendas',
            leitura.vendas,
            'finalizadora',
          )}
          {renderLeitura(TbCoins, 'Suprimento', leitura.reforco, 'reforco')}
          {renderLeitura(TbCoins, 'Sangria', leitura.sangria, 'sangria')}
          <Leitura
            icon={IoWalletOutline}
            label="Saldo final"
            finalizadoras={totals}
          />
          <ContainerButtonModal>
            <button
              type="button"
              className="button-imprimir"
              onClick={async () => {
                await handleImprimir();
              }}
            >
              <IoPrintOutline size={18} />
              Imprimir
            </button>
            <button
              type="button"
              className="button-ok"
              onClick={handleCloseModal}
            >
              OK
            </button>
          </ContainerButtonModal>
        </section>
      )}
    </Modal>
  );
};
