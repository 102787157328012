import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { InputMoney } from '~/components/NovosInputs';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { aberturaPdv } from '~/pages/PdvOnline/services/abertura-pdv';
import { SelectType } from '~/pages/PdvOnline/types';
import { codTipoMov } from '~/pages/PdvOnline/utils/codTipoMov';
import { Modal } from '..';
import { InputFinalizadora } from '../../InputFinalizadora';
import {
  ButtonRefoco,
  InputFinalizadoraContent,
  ReforcoContent,
} from './styles';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal);

export const ModalReforco: React.FC = () => {
  const modalContentRef = useRef<HTMLDivElement | null>(null);
  const buttonModalRef = useRef<HTMLButtonElement | null>(null);

  const {
    isModalOpen,
    onCloseModal,
    initialStatePdv,
    handleChangeOpenModalFunction,
  } = usePdvOnline();

  const {
    register,
    control,
    setValue,
    resetField,
    watch,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm();

  const modalOpen = isModalOpen.isOpen && isModalOpen.modal === 'reforco';
  const watchCodFinalizadora: SelectType | undefined =
    watch('cod_finalizadora');
  const watchVlrReforco: string = watch('vlr_reforco');

  const onClose = useCallback(() => {
    onCloseModal();
    handleChangeOpenModalFunction(true);
    resetField('cod_finalizadora');
    setValue('vlr_reforco', '');
  }, [handleChangeOpenModalFunction, onCloseModal, resetField, setValue]);

  useEffect(() => {
    const handleCloseModalKey = (ev: KeyboardEvent) => {
      if (modalOpen && ev.key === 'Escape') onClose();
    };

    window.addEventListener('keydown', handleCloseModalKey);
    return () => window.removeEventListener('keydown', handleCloseModalKey);
  }, [modalOpen, onClose]);

  useEffect(() => {
    if (modalOpen && watchCodFinalizadora && modalContentRef.current) {
      const inputVlr: HTMLInputElement | null =
        modalContentRef.current.querySelector('[name="vlr_reforco"]');
      if (inputVlr) inputVlr.focus();
    }
  }, [modalOpen, watchCodFinalizadora]);

  useEffect(() => {
    if (modalOpen && modalContentRef.current) {
      const input: HTMLInputElement | null =
        modalContentRef.current.querySelector('[name="cod_finalizadora"]');
      if (input) {
        input.value = '';
        input.focus();
      }
    }
  }, [getValues, modalOpen]);

  const handleUnsuccessfulOperation = useCallback(async (message: string) => {
    const isMissingMovementType = message.includes(
      'Para lançar esse movimento é necessário ter o tipo',
    );

    if (isMissingMovementType) {
      await MySwal.fire({
        title: message,
        icon: 'warning',
        html: `
          <span>
            <p>1. Vá para a tela “Cadastro de Tipo de Movimento de Caixa“.</p>
            <p>2. Cadastre com o tipo e operação compatível à operação desejada.</p>
            <p>3. Volte aqui e tente novamente.</p>
          </span>
        `,
      });
    }
  }, []);

  const handleClickOk = useCallback(async () => {
    const isInvalidInput =
      !watchCodFinalizadora || !watchVlrReforco || watchVlrReforco === '0,00';

    if (isInvalidInput || !initialStatePdv) return;

    const { success, data, message } = await aberturaPdv.insereMovimento(
      initialStatePdv.num_pdv,
      initialStatePdv.cod_loja,
      {
        cod_operador: initialStatePdv.cod_operador,
        cod_tipo_mov: codTipoMov.REFORCO,
        val_mov: watchVlrReforco,
        cod_finalizadora: [Number(watchCodFinalizadora.value)],
      },
    );

    if (!success) {
      handleUnsuccessfulOperation(message);
      return;
    }

    if (data) {
      onClose();
      toast.success('Reforço aplicado com sucesso');
    }
  }, [
    handleUnsuccessfulOperation,
    initialStatePdv,
    onClose,
    watchCodFinalizadora,
    watchVlrReforco,
  ]);

  useEffect(() => {
    const handleEnterKeyPress = (ev: KeyboardEvent) => {
      if (modalOpen && buttonModalRef.current && ev.key === 'Enter') {
        buttonModalRef.current.click();
      }
    };

    window.addEventListener('keydown', handleEnterKeyPress);
    return () => window.removeEventListener('keydown', handleEnterKeyPress);
  }, [modalOpen]);

  return (
    <Modal isOpen={modalOpen} title="Reforço" onClose={onClose}>
      <ReforcoContent ref={modalContentRef}>
        <InputFinalizadoraContent>
          <InputFinalizadora
            name="cod_finalizadora"
            register={register}
            isError={!!errors.cod_finalizadora}
            control={control}
            changeSelected={(selected) => {
              setValue('cod_finalizadora', selected);
            }}
            disabled={isSubmitting}
            codLoja={initialStatePdv ? initialStatePdv.cod_loja : 1}
            iniciaRequest={modalOpen}
            paramsApi={{ 'finalizadora.tipo_finalizadora': 0 }}
          />
        </InputFinalizadoraContent>
        <InputMoney
          label="Valor:"
          placeholder="0,00"
          min={0}
          decimals={2}
          max={9999}
          maxLength={9999}
          name="vlr_reforco"
          register={register}
          autoComplete="off"
          control={control}
          showIcon={false}
          isError={!!errors.vlr_reforco}
          onInput={(ev: any) => {
            setValue('vlr_reforco', ev.target.value);
          }}
          disabled={isSubmitting}
        />
        <ButtonRefoco
          disabled={
            isSubmitting ||
            !watchCodFinalizadora ||
            watchVlrReforco === '' ||
            watchVlrReforco === '0,00'
          }
          type="button"
          ref={buttonModalRef}
          onClick={handleClickOk}
        >
          Ok
        </ButtonRefoco>
      </ReforcoContent>
    </Modal>
  );
};
