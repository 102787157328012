import styled from 'styled-components';

export const ModalPedidoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const ButtonAction = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 11.875rem;
  height: 9.375rem;

  padding: 1.875rem;
  border-radius: 0.3125rem;
  border: none;

  font-size: large;
  gap: 0.5rem;
`;

export const ContentLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0.625rem;

  border-bottom: 1px solid rgba(0, 0, 0, 0.5);

  p {
    font-size: large;
  }
`;
