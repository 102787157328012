import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BiPlusMedical } from 'react-icons/bi';
import { FaCloudUploadAlt, FaPrint } from 'react-icons/fa';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { Modal } from '..';
import { ButtonAction, ContentLabel, ModalPedidoContainer } from './styles';
import { IoMdPaperPlane } from 'react-icons/io';
import { consultarVendasService } from '~/pages/PdvOnline/services/consulta-venda';
import ImprimirCupomVenda from '~/utils/classes/ImpressaoRecibo/PdvOnline/ImprimirCupomVenda';
import { gerarCupomCaixa } from '../ModalBuscarVendas/utils/gerarCupomCaixa';
import { aberturaPdv } from '~/pages/PdvOnline/services/abertura-pdv';
import useAuth from '~/hooks/useAuth';
import { toast } from 'react-toastify';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal);

export const ModalPedidoRegistrado: React.FC = () => {
  const [alterTab, setAlterTab] = useState(false);

  const {
    isModalOpen,
    handleResetPdv,
    onCloseModal,
    mainContentRef,
    infoPedido,
    onOpenModal,
    initialStatePdv,
  } = usePdvOnline();

  const { user } = useAuth();

  const buttonNovaVenda = useRef<HTMLButtonElement | null>(null);

  const modalOpen =
    isModalOpen.isOpen && isModalOpen.modal === 'pedidoRegistrado';

  useEffect(() => {
    if (buttonNovaVenda.current && modalOpen) buttonNovaVenda.current.focus();
  }, [modalOpen]);

  useEffect(() => {
    if (mainContentRef.current && alterTab) {
      const tabProduto: HTMLButtonElement | null =
        mainContentRef.current.querySelector('[id="tab-Produtos (Alt + 1)"]');

      if (tabProduto) {
        tabProduto.click();
        setAlterTab(false);
      }
    }
  }, [alterTab, mainContentRef]);

  const handleNovaVenda = useCallback(() => {
    handleResetPdv();
    onCloseModal();
    setAlterTab(true);
  }, [handleResetPdv, onCloseModal]);

  const processCoupon = useCallback(
    async (action: 'open' | 'send') => {
      if (infoPedido.tipo_status !== 0) return;

      const { success, data } = await consultarVendasService.indexImprimir(
        infoPedido.cod_seq_pedido,
      );

      if (success) {
        const reciboGerado = new ImprimirCupomVenda(data).createCupomVenda();
        const cupom = gerarCupomCaixa(
          reciboGerado,
          action,
          'comprovante_venda',
        );

        if (action === 'send' && cupom instanceof FormData) {
          onOpenModal('enviarEmailComprovanteVenda', {
            enviarEmailComprovanteVenda: {
              num_pedido: infoPedido.num_pedido,
              formData: cupom,
              returnTo: 'pedidoRegistrado',
            },
          });
        }
      }
    },
    [
      infoPedido.cod_seq_pedido,
      infoPedido.num_pedido,
      infoPedido.tipo_status,
      onOpenModal,
    ],
  );

  const handleTransmitirNFCe = useCallback(async () => {
    if (!initialStatePdv) return;

    if (!user)
      return toast.warning(
        'Não foi possível encontrar usuário, tente novamente ou entre em contato com o suporte',
      );

    const hasCertificate = await aberturaPdv.getCertificado(
      initialStatePdv.cod_loja,
    );

    const findTelaParametrizcaoNfe = user.controleAcessoTela.some(
      (c) => c.cod_controle === -2 && c.cod_tela === 41,
    );

    if (!findTelaParametrizcaoNfe)
      return toast.warning(
        'Usuário sem acesso à tela de Parametrização de NFe, contate o administrador do sistema.',
      );

    if (!hasCertificate) {
      await MySwal.fire({
        icon: 'warning',
        html: `
          <span>
            <p>
              Para transmitir NFC-e primeiramente é preciso configurar o certificado
              digital da loja!
            </p>
          </span>
        `,
      });

      return;
    }

    const { success, message } =
      await consultarVendasService.indexVerificaSerie(initialStatePdv.cod_loja);

    if (!success) {
      await MySwal.fire({
        icon: 'warning',
        title: message,
        html: `
            <span>
              <p>1. Vá para a tela de Cadastro de Série de NF.</p>
              <p>2. Insira uma série vinculada a espécie NFCe para a loja ${initialStatePdv.cod_loja}.</p>
              <p>3. Ajuste a numeração do último documento emitido.</p>
              <p>4. Volte aqui e tente novamente.</p>
            </span>
          `,
      });
    }
  }, [initialStatePdv, user]);

  return (
    <Modal isOpen={modalOpen} title="Pedido registrado">
      <ModalPedidoContainer>
        <ButtonAction onClick={handleNovaVenda} ref={buttonNovaVenda}>
          <BiPlusMedical size={40} color="#019F0F" />
          Nova Venda
        </ButtonAction>
        <ButtonAction
          onClick={async () => {
            await processCoupon('open');
          }}
        >
          <FaPrint size={40} color="#585858" /> Imprimir Cupom
        </ButtonAction>
        <ButtonAction
          onClick={async () => {
            await processCoupon('send');
          }}
        >
          <IoMdPaperPlane size={40} color="#61B554" /> Enviar...
        </ButtonAction>
        <ButtonAction
          onClick={async () => {
            await handleTransmitirNFCe();
          }}
        >
          <FaCloudUploadAlt size={40} color="#297BE4" />
          Transmitir NFC-e
        </ButtonAction>
      </ModalPedidoContainer>
      <ContentLabel>
        <p>Emissão de NFC-e pendente!</p>
      </ContentLabel>
    </Modal>
  );
};
